.app__skill {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
    

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
      
    }
  }
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    // background-color: whitesmoke;


  }
  
  .app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  



    img {
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;


// About skill boxes
// margin: 4rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: rgba(255, 255, 255, 0.8);
      color: #000;
    }
  
    @media screen and (min-width: 2000px) {
      width: 270px;
      margin: 2rem 4rem;
  
      img {
        height: 250px;
      }
    }
  }