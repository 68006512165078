.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 1rem;
  }
  
  .app__about-profile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    max-width: 70em;
    // position: absolute;
    // flex-wrap: wrap;
    // margin-top: 2rem;
    // width: 100%;
    // flex-direction: column;

    
    // transform: translateY(-50%);
    
    // background-color: aqua;
  }
  
  .app__about-desc {
    display: flex;
    justify-content: center;
    // align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 2rem;
    // width: 100%;
    // flex-direction: row;
    
    // background-color: aqua;

    @media screen and (max-width: 820px) {
      margin-left: 0px;
      margin-top: 1rem;
    }
  }

  .app__about-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    width: 100%;
    max-width: 1300px;  



    img {
        margin-top: 2rem;
        // vertical-align: center;
        align-content: center;
        width: 300px;
        height: 600px;
        object-fit: cover;

        display: block;
        margin: auto;
        
        
        
        // margin: 4rem;
        border-radius: 15px;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: rgba(255, 255, 255, 0.8);
        color: #000;
    }
  
    @media screen and (max-width: 820px) {
      flex-direction: column;
    }
    @media screen and (min-width: 2000px) {
      width: 1600px;
    //   margin: 2rem 4rem;
  
    //   img {
    //     height: 320px;
    //   }
    }
  }